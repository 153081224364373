import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle2 from '../assets/Images/Vehicle-2-white.png'
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Marquee from "react-fast-marquee";

export default function ContactSection() {
    return (
        <section id='contact' className='section-bg'>
            <Container>
                <Row>
                    <Col md={6}>
                        <h2>Get in<span> touch with us</span></h2><br />
                        <p>We Are Just A Click Away</p>
                        <div className="contacts">
                            <div class="icon">
                                <PhoneIcon />
                            </div>
                            <a href="tel:+91 99090 08176">+91 99090 08176 (Rajesh)</a><br />
                            <a href="tel:+91 99092 28888">+91 99092 28888 (Hiren)</a>
                        </div>
                        <div className="contacts">
                            <div class="icon">
                                <EmailIcon />
                            </div>
                            <a href="mailto:info@vconcrete.in">info@vconcrete.in</a>
                        </div>
                        <div className="contacts">
                            <div class="icon">
                                <RoomIcon />
                            </div>
                            <p>54 & 55, Patel Nagar, <br /> At. Post:Baladia,<br /> Bhuj(Kutch) Gujarat,<br/>Pincode: 370427</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1541.8697080717259!2d69.6042923205454!3d23.199203880532746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSur.%20No.%20158%2F2P%2C%20%20Opp.%20Shivparas%20Temple%2C%20At%20Post%3A%20Sukhpar%2C%20370040%20Ta.%20Bhuj%20-%20Kutch%20(Gujarat)!5e0!3m2!1sen!2sin!4v1650109039525!5m2!1sen!2sin" title={'Map Location'} width="100%" height="400" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </Container>
            <Marquee direction="left" gradientWidth={10}>
                <img src={Vehicle2} alt="Vehicle" />
            </Marquee>
        </section>
    )
}
