import React, { useEffect } from 'react'
import { contactUs } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import { Container, Col, Row } from 'react-bootstrap';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactForm from '../components/ContactForm'

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{contactUs.title}</title>
                <meta name="description" content={contactUs.description} />
                <link rel="canonical" href={contactUs.canonical} />
            </MetaTags>
            <section id="breadcrumbs" title='Quality Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Vconcrete' class="breadcrumbs">
                <div class="container">
                    <h1>Contact Us</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>/ Contact Us</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <Row>
                        <Col md={4} xs={12} className="contact-info my-auto">
                            <div className="icon mt-unset"><PhoneIcon /></div>
                            <h3>Call</h3>
                            <p>
                                <a href="tel:+91 99090 08176">+91 99090 08176 (Rajesh)</a>
                                <br />
                                <a href="tel:+91 99092 28888">+91 99092 28888 (Hiren)</a>
                            </p>
                            <p></p>
                            <div className="icon"><EmailIcon /></div>
                            <h3>Email</h3>
                            <p><a href="mailto:info@vconcrete.in">info@vconcrete.in</a></p>
                            <div className="icon"><RoomIcon /></div>
                            <h3>Address</h3>
                            <p>54 & 55,Patel Nagar, <br /> At. Post:Baladia, <br />Bhuj(Kutch) Gujarat, <br />Pincode:370427 <br /> </p>
                        </Col>
                        <Col md={8}>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1541.8697080717259!2d69.6042923205454!3d23.199203880532746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSur.%20No.%20158%2F2P%2C%20%20Opp.%20Shivparas%20Temple%2C%20At%20Post%3A%20Sukhpar%2C%20370040%20Ta.%20Bhuj%20-%20Kutch%20(Gujarat)!5e0!3m2!1sen!2sin!4v1650109039525!5m2!1sen!2sin" title={'Map Location'} width="100%" height="500" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </div>
    )
}

export default ContactUs
