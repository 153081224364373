import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ReadyMixedConPic1 from '../assets/Images/vconcrete_testing.png'
import ReadyMixedConPic2 from '../assets/Images/rmcv.png'
import ReadyMixedConPic3 from '../assets/Images/vconcrete.png'
import { Button } from 'react-bootstrap'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


export default function MissionAndVision() {
    return (
        <section className="mission">
            <Container>
                <div className="text-center">
                    <h2 className='center'>Best Ready<span> Mixed Concrete(RMC) Provider in Kutch, Gujarat</span></h2><br />
                    <p className='text-center'>Right Product | Right Price | Right Time</p>
                </div>
                <Row>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-concrete-1">
                                        <div className="icon-box">
                                            <img src={ReadyMixedConPic1} alt="Commitment" className="or-1 img-top-radius" />
                                        </div>
                            <h3>The Concept of Concrete</h3>
                            <div className="d-flex flex-column justify-content-between height-23">
                                <p>Back from the days of Modern Civilisation & with fast growing technology, the roll of Concrete has become one of the major & integral part to be played in Civil Construction.</p>
                                <Link to="/ready-mix-concrete" ><Button  className='btn-1'>Read more <ArrowForwardIcon fontSize='small' /></Button></Link>
                            </div>
                        </div>
                    </Col>
                    <Col  md={4} className="mission-row">
                        <div className="card-mission-concrete-2">    
                                        <div className="icon-box">
                                            <img src={ReadyMixedConPic2} alt="Values" className="or-2 img-top-radius" />
                                        </div>
                            <h3>Ready Mix Concrete (RMC) at VConcrete</h3>
                            <div className="d-flex flex-column justify-content-between height-23">
                                <p>RMC gives us more effective strength with less cost & less time consumption.</p>
                                <Link to="/ready-mix-concrete" className="link-primary-1"><Button  className='btn-1'>Read more <ArrowForwardIcon fontSize='small' /></Button></Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-concrete-3">
                                        <div className="icon-box">
                                            <img src={ReadyMixedConPic3} alt="Vision" className="or-3 img-top-radius" />    
                                        </div>
                            <h3>Why choose VConcrete for RMC?</h3>
                            <div className="d-flex flex-column justify-content-between height-23">
                                <p>RMC production at our plants means NO compromise in our premium Quality, on time delivery and at your door step.</p>
                                <Link to="/ready-mix-concrete" className="link-primary-1"><Button className='btn-1'>Read more <ArrowForwardIcon fontSize='small' /></Button></Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
