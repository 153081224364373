import React, { useEffect } from 'react'
import { business } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import ContactSection from '../components/ContactSection'
// import About from '../assets/Images/vconcrete.png'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle1 from '../assets/Images/Vehicle-1-white-right.png'
// import MissionAndVision from '../components/MissionAndVision'
import Marquee from "react-fast-marquee";
import AboutVgcpl from '../assets/Images/vgcpl-1.png'
// import AboutVgcpl2 from '../assets/Images/vgcpl-2.png'
// import { Link } from 'react-router-dom'
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


const Business = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{business.title}</title>
                <meta name="description" content={business.description} />
                <link rel="canonical" href={business.canonical} />
            </MetaTags>
            <section id="breadcrumbs" title='Quality Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Vconcrete' class="breadcrumbs">

                <div class="container">
                    <h1>Business</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>/ Business</li>
                    </ul>
                </div>
            </section>
            <section id='about' className="entry section-bg bg-1">
                <Container>
                    <div className="part-2">
                        <Row>
                            <Col md={6}>
                                <h2>VConcrete <span>A Unit Of  VGCPL</span></h2>
                                <div className="content">
                                    <p>A Unit of <b>VGCPL</b> VConcrete having its base at Baladia Village of Bhuj Tehsil in Kachchh district. VConcrete under the guidance of Vishram Karsanbhai Gorasia and under the dynamic leadership of Jadavji Gorasia, Parbat Gorasia and Bharat Gorasia is climbing the new heights in field of construction. VConcrete is the top most manufacturer and supplier of RMC in the district of Kachchh.</p>
                                    <p>Achieving the milestone of being top construction company of Kachchh, Now we are planning to take this business on the national horizon to serve best in field of concrete construction.</p>
                                    <p>Many big manufacturing and infrastructure firms, Agri-Buinesses, Logistics, Container freight services are in the list of our satisfied clients. With this rich and strong background in field of construction we at VConcrete always like to grow and nurture ourselves more and more to achieve excellence in whatever we do.</p>
                                    <p>Quality of RMC is directly dependent on the raw material used and the type of mixer plant and VConcrete is having one of the finest human resources available on its sites.With experienced eyes of mentors and qualified professionals, we at VConcrete and VGCPL believe in no compromise policy in our premium quality of work.</p>
                                </div>
                            </Col>
                            <Col md={6} className='text-center'>
                                <img src={AboutVgcpl} alt="VGCPL Construction Pvt. Ltd." className="img-fluid bg" />
                            </Col>
                            <div className='business-content-left'>
                            <p>Innovating and learning is what we appreciate and hence we follow the same in our business, wheather it be through technology, through machinery or even if it is achieved through the means of human resources.To meet the increasing demand of ready mix concrete and everyday expanding construction business, VConcrete recently thought of starting new unit in Kachchh and so now we have <b>SIX</b> very well equipped and technically sound working units.</p>
                            <p>With our versatility and quality service combined with experience and expertise we at VConcrete are always on standby mode to serve you <strong>THE BEST</strong>.</p>
                            </div>
                        </Row>
                    </div>

                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee>
                <div className="touch bg-2">
                    <div className="vehicle-track">
                    </div>
                </div>
            </section>
            <ContactSection />
        </div>
    )
}

export default Business
