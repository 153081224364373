import React, { useEffect } from 'react'
import { concrete } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import ContactSection from '../components/ContactSection'
// import About from '../assets/Images/vconcrete.png'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle1 from '../assets/Images/Vehicle-1-white-right.png'
// import MissionAndVision from '../components/MissionAndVision'
import Marquee from "react-fast-marquee";
// import AboutVgcpl from '../assets/Images/vgcpl-2.png'
import ReadyMixedConPic1 from '../assets/Images/vconcrete_testing.png'
import ReadyMixedConPic2 from '../assets/Images/rmcv.png'
import ReadyMixedConPic3 from '../assets/Images/vconcrete.png'

const ConcreteDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{concrete.title}</title>
                <meta name="description" content={concrete.description} />
                <link rel="canonical" href={concrete.canonical} />
            </MetaTags>
            <section id="breadcrumbs" title='Quality Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Vconcrete' class="breadcrumbs">
                <div class="container">
                    <h1>Ready Mixed Concrete</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>/ Ready Mix Concrete</li>
                    </ul>
                </div>
            </section>
            <section id='about' className="entry section-bg bg-1">
                <Container className='concrete-section' >
                    <Row>
                        <Col md={6} className='text-center'>
                            <div className='concrete-img-1'>
                            <img src={ReadyMixedConPic1} alt="Concrete Manufacturer in Kutch, Gujarat | Concrete Supplier | VConcrete"  className="img-fluid bg" /> 
                            </div>
                        </Col>
                        <Col md={6}>
                            <h2>The  <span>Concept of Concrete</span></h2>
                            <div className="content">
                                <p>Right from the days of Roman Empire which prevailed around 8500 years back, concrete has always been an integral part of our civilization. From small structures to giant sky crappers, concrete is the one what you see. These days concrete is most widely used human-made material.</p>
                                <p><strong>"The word Concrete"</strong> actually has its origin from latin word “concretus” which simply means compact or condensed. Many types of concrete are available in market, differentiated by the proportions of the main ingredients. Strength, Density, Chemical compositions may also vary accordingly.</p>
                                <p>Aggregate in concrete simply means bigger chunks of material like crushed rocks such as limestone, or granite, along with materials such as sand.</p>

                                <p>Cement is generally a term associated with "concrete." To produce concrete from most cements, water is mixed with the dry powder and aggregate mentioned above, which produces a semi-liquid composition that can be shaped into any form. The water reacts with the cement, which bonds the other components together, creating a robust stone-like material. Thus, we ultimately get solidified concrete which is rock solid material used to create gigantic structures.</p>
                               
                            </div>
                            
                        </Col>
                        <div className="content-align-left">
                                {/*                            
                                <p>Cement is generally a term associated with "concrete." To produce concrete from most cements, water is mixed with the dry powder and aggregate mentioned above, which produces a semi-liquid composition that can be shaped into any form. The water reacts with the cement, which bonds the other components together, creating a robust stone-like material. Thus, we ultimately get solidified concrete which is rock solid material used to create gigantic structures.</p> */}
                            </div>
                    </Row>
                    <div  className="part-2">
                        <Row id='why-mixed-concrete'>
                            <Col md={6}>
                                <h2>Ready Mix Concrete (RMC) <span>at VConcrete</span></h2>
                                <div className="content">
                                    <p>We at V Concrete work with volumetric concrete mixer which delivers the ready mix in a dry state and then mixes the concrete on site. This process helps to combine a precise amount of all aggregate mentioned above like rock, sand, water and cement together by weight, also allowing specialty concrete mixtures to be developed and implemented right there on construction sites.</p>
                                    <p>The first ready-mix factory was built in the 1930s, but the industry did not begin to progress much until the late 60s, but after that it has never seen a downfall in any of the circumstances.</p>
                                    <p>Ready-mix concrete is often preferred over other of same fraternity due to many factors like cost and its versatility, from small water tanks to big water reservoirs to miles long bridges. It also has comparatively longer life than other products.</p>
                                    <p>If we consider an example of roadways than there It has an average life span of 30 years under heavy traffic areas while normal concrete may just sustain for about 10 years.</p>
                                    <p>Ready-mix concrete, or RMC is also known as tailor made concrete as it is specifically produced right there on the construction site or at some nearby RMC manufacturing unit.</p>
                                </div>
                            </Col>
                            <Col md={6} className='text-center'>
                                <img src={ReadyMixedConPic2} alt="Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Ready Mix Concrete(RMC) Company | VConcrete" className="img-fluid bg" />
                            </Col>
                        </Row>

                        <Row>
                        <Col md={6} className='text-center'>
                            <img src={ReadyMixedConPic3} alt="Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Ready Mix Concrete(RMC) Company | VConcrete" className="img-fluid bg" /> 
                        </Col>
                        <Col md={6}>
                            <h2>Why <span>choose VConcrete for RMC?</span></h2>
                            <div className="content">
                                <p>We at V Concrete have <b>SIX</b> units established on all directions of Kachchh and hence we easily provide complete construction solutions on both east and west sides of Kachchh.</p>
                                <p>V Concrete having wide range of modern day machineries and with extraordinary human skills, makes it one of the wisest choice for Ready mix concrete. Along with its decade long experties and time-tested services leaves you with no better option than V Concrete.</p>
                                <p><strong>“WE WORK TO TRANSFORM YOUR DREAMS INTO REALITY”</strong></p>
                                <p>This is the motto we follow and have no doubt in this as our clients says much more about us than what we do. Trust building and Customer relation is what we always look upon and we sincerely consider our clients to be more than above everything we have.</p>
                            </div>
                            
                        </Col>
                        </Row>
                    </div>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee>
            </section>
            {/* <MissionAndVision /> */}
            <ContactSection />
        </div>
    )
}

export default ConcreteDetails
