import React, { useEffect } from 'react'
import { vconcrete } from '../MetaTags'
import MetaTags from 'react-meta-tags';
// import AboutNCPL from '../assets/Images/vgcpl.png'
// import About from '../assets/Images/vconcrete_home_about.png'
import { Container, Col, Row } from 'react-bootstrap'
import Machinery from '../components/Machinery'
import Marquee from "react-fast-marquee";
import Vehicle1 from '../assets/Images/Vehicle-1-white-right.png'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import Slider from "react-slick";
import { Link } from 'react-router-dom'
import Overview from '../assets/Images/gas-tank-concrate-supplayer.png'
import VconcreteImg from '../assets/Images/V_CONCRETE.png'
import HistoryImg from '../assets/Images/v_concrete_history.png'


const VGCPL = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     arrows: true,
    //     slidesToShow: 1,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     slidesToScroll: 1,
    // };
    return (
        <div>
            <MetaTags>
                <title>{vconcrete.title}</title>
                <meta name="description" content={vconcrete.description} />
                <link rel="canonical" href={vconcrete.canonical} />
            </MetaTags>
            <section id="breadcrumbs" title='Quality Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Vconcrete' class="breadcrumbs">
                <div class="container"> 
                    <h1>VConcrete</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li> / VConcrete</li>
                    </ul>
                </div>
            </section>
            <section className="entry" id='about'>
                <Container>
                    <Row>
                        <Col md={7} className="my-auto">
                            <h2>Who<span> We Are</span></h2>
                            <div className="content">
                            <p><b><i>“TRUSTWORTHINESS”</i></b> of our valued Customers makes us stronger to grow more and more without any compromises in our premium Quality product and/or workmanship.</p>
                                <p>We are one of the Top-Players in the business of Civil Construction just because of our Integrity, Dedication, Courage, Honesty, Hard Work and Commitment towards our Clients.</p>
                                <p>Presently we are having <b>SIX</b> Ready Mix Plants for our own use as well as we cater to our Customers such as Adani Group, Reliance Group, AMW  and many more local contractors in the district of Kutch, Gujrat, India.</p>
                            </div>
                        </Col>
                        <Col md={5} className="position-relative">
                            <img src={Overview} alt="Best Civil Construction Company in Kutch, Gujarat | VConcrete" className="img-fluid my-auto bg" />
                        </Col>
                    </Row>
                </Container>
                
                <section id='about' className="entry section-bg bg-1">
                <Container className='vconcrete-section'>
                    <Row>
                        <Col md={6} className='text-center'>
                        
                            <img src={VconcreteImg} alt="Ready Mix Concrete(RMC) Plant Manufacturer in Kutch, Gujarat | VConcrete" className="img-fluid bg" />
                        
                        </Col>
                        <Col md={6}>
                            <h2>VConcrete <span>: Best Civil Construction Company in Kutch, Gujarat</span></h2>
                            <div className="content">
                                <p>
                                <strong>VConcrete</strong> has its base in Baladia Village of Bhuj tehsil in Kachchh district. VConcrete under the guidance of Vishrambhai Karsan Gorasia and under the dynamic leadership of Jadavji Gorasia, Parbat Gorasia and Bharat Gorasia is climbing the new heights in field of construction. VConcrete is the sole concrete manufacturing plant in the district of Kachchh.
                                </p>
                                <p>Achieving the milestone of being top construction company of Kachchh, Now we are planning to take this business on the national horizon to serve best in the field of concrete construction.</p>
                               
                            </div>
                        </Col>
                        <div className='vconcrete-content-left'>
                            <p>Many big manufacturing and infrastructure firms, Agri-Buinesses, Logistics, Container freight services are in the list of our satisfied clients. With this rich and strong background in field of construction we at VConcrete always like to grow and nurture ourselves more and more to achieve excellence in whatever we do.</p>
                                <p>Quality is directly dependent on resources and VConcrete is having one of the finest human resources available on its sites. With experienced eyes of mentors and qualified professionals, we at VConcrete and VGCPL believe in no compromise policy in our premium quality of work.</p>
                                <p>Innovating and learning is what we appreciate and hence we follow the same in our business, wheather it be through technology, through machinery or even if it is achieved through the means of human resources.</p>
                                <p>To meet the increasing demand of ready mix concrete and everyday expanding construction business, VConcrete recently thought of starting new unit in Kachchh and so now we have <b>SIX</b> very well equipped and technically sound working units.</p>
                                <p>With our versatility and quality service combined with experience and expertise we at VConcrete are always on standby mode to serve you THE BEST.</p>
                            </div>
                    </Row>
                    <div className="part-2">
                        <Row>
                            <Col md={6}>
                                <h2>Our <span>History</span></h2>
                                <div className="content">
                                    <p>Late Sri Vishram bhai Karsan bhai Gorasia, the founder, later on became the Managing Director of the Company <b>(VGCPvt.Ltd.)</b>, at the very onset of his career, he started to undertake very small construction ventures in his own home village (Baladia – Taluka: Bhuj – District: Kutch – Gujarat – India), but with a very <b>BIG</b> foresight & <b>WIDE</b> speculation of future development in Construction business. </p>
                                    <p>Slow & steadily, but with his dedication, hard work and passion he emerged to become one of the most trusted Civil Contractors in the local area of Kutch district.</p>
                                    <p>On attaining a certain age and to do some Social Service for the mankind, he voluntarily retired himself from the job, handing over the baton, to his eldest son, Sri Jadavji Vishrambhai Gorasia.</p>
                                    <p>Now, to carry on the legacy of his father, Sri Jadavji Vishrambhai Gorasia, registered the family construction business into a registered private limited company in the year 2008, duly named as <b><i>“VISHRAMBHAI GORASIA CONSTRUCTION PRIVATE LIMITED”.</i></b> </p>
                                </div>
                               
                            </Col>
                            <Col md={6} className='text-center'>
                                <img src={HistoryImg} alt="Civil Construction Contractors in Kutch, Gujarat | VConcrete" className="img-fluid bg" />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee>
            </section>
            </section>
            <section>
                <Container>
                    <div className="text-center">
                        <h2>OUR <span>CLIENTS</span></h2>
                        {/* <h3 className="sub">We Have Heavy Duty Machineries</h3> */}
                    </div>
                </Container>
                <Machinery />
            </section>
            {/* <section className="section-bg rmc">
                <Container>
                    <Row>
                        <Col md={5} className="position-relative">
                            <img src={RmcPlant} alt="RMC Plant in Bhuj" className="img-fluid my-auto bg" />
                        </Col>
                        <Col md={7} className="my-auto">
                            <h2>RMC <span>Plant in Bhuj</span></h2>
                            <div className="content">
                                <p>We are specialized for our on-time resolution, lowest scrap generation, maximum output and execution of architectural development.</p>
                                <p>We have successfully completed many projects e.g. commercial, residential, temple, trust building, commercial building, healthcare buildings, education structures, pilgrimage sites, etc. We have also completed government structures and many projects under sub-contracting formula. It's time to grow and we are pretty sure about our future because we have strength, dedication and enthusiastic team.</p>
                                <p>That's true if you wish to design your future, you need to work on your efficiency. During the period we've worked on it to achieve many goals.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section className="section-bg ncpl">
                <Container>
                    <Row>
                        <Col md={9} className="my-auto">
                            <h2>For better future &amp; business, <br /> don't hesitate to contact us...</h2>
                        </Col>
                        <Col md={3} className="text-center my-auto">
                            <Link to="/contact-us" className="btn btn-primary">Contact Us <ArrowRightAltIcon /></Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default VGCPL
