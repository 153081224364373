export const menu = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'VConcrete',
        path: '/vconcrete'
    },
    {
        label: 'Business',
        path: '/business'
    },
    {
        label: 'Plants',
        path: '/plants'
    },
    {
        label: 'Gallery',
        path: '/gallery'
    },
    {
        label: 'Contact Us',
        path: '/contact-us'
    },
   
    
]
