import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Client1 from '../assets/Images/clients/v_concrete_client_adani.png'
import Client2 from '../assets/Images/clients/v_concrete_client_swami_mandir_bhuj.png' 
import Client3 from '../assets/Images/clients/vconcrete_client_amw.png'
import Client4 from '../assets/Images/clients/vconcrete_client_ashapura_minechem_ltd.png'
import Client5 from '../assets/Images/clients/vconcrete_client_bkt.png'
import Client6 from '../assets/Images/clients/vconcrete_client_cargill.png'
import Client7 from '../assets/Images/clients/vconcrete_client_cgpl.png'
import Client8 from '../assets/Images/clients/vconcrete_client_gspc.png'
import Client9 from '../assets/Images/clients/vconcrete_client_gujarati_rousism.png'
import Client10 from '../assets/Images/clients/vconcrete_client_hindusthan.png'
import Client11 from '../assets/Images/clients/vconcrete_client_ihi.png'
import Client12 from '../assets/Images/clients/vconcrete_client_indian_oil.png'
import Client13 from '../assets/Images/clients/vconcrete_client_l_and_t.png'
import Client14 from '../assets/Images/clients/vconcrete_client_welspun.png'

const Machinery = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        
        <div className="text-center">
            <Slider {...settings}>
                <div className="machinery">
                    <img src={Client1} alt="Tipper" className="img-fluid" />
                    <div className="count">
                        {/* <h4>adani</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client2} alt="Transit Mixer" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>11</span> Transit Mixer</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client3} alt="Front Wheel Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>3</span> Front Wheel Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client4} alt="Excavator" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> Excavator</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client5} alt="Tractor Loader / Trolley" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>1</span> Tractor Loader / Trolley</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client6} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client7} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client8} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client9} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client10} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client11} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client12} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client13} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
                <div className="machinery">
                    <img src={Client14} alt="JCB Backhoe Loader" className="img-fluid" />
                    <div className="count">
                        {/* <h4><span>4</span> JCB Backhoe Loader</h4> */}
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default Machinery
