// Core
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from "react-router-dom";
// BootStrap
import { Container, Col, Row } from 'react-bootstrap';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RoomIcon from '@mui/icons-material/Room';
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Logo from '../assets/Images/Footerlogo.png'
import LaunchIcon from '@mui/icons-material/Launch';
// import { WhatsApp } from '@mui/icons-material';

const Footer = () => {
    let url = useLocation().pathname;
    const [urlHomepage, setUrlHomepage] = useState('')
    const [urlNcpl, setUrlNcpl] = useState('')
    const [urlContact, setUrlContact] = useState('')
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        navigateTo(url)
        // eslint-disable-next-line
    }, [])

    const navigateTo = (url) => {
        if (url === "/") {
            setUrlHomepage('active');
            setUrlNcpl('');
            setUrlContact('');
        } else if (url === "/vconcrete") {
            setUrlHomepage('');
            setUrlNcpl('active');
            setUrlContact('');
        } else if (url === "/contact-us") {
            setUrlHomepage('');
            setUrlNcpl('');
            setUrlContact('active');
        }
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <div id="footer">
                <Container>
                    <Row>
                         <Col md={4} className="logo-content">
                            <img src={Logo} alt="VConcrete Ready Mix Concrete Logo" className='logo' />
                            <div>
                                <h6>V Concrete Built Pvt.Ltd. is one the most 
                                    <br /> wanted RMC producers in Kutch district,Gujarat.</h6>
                            </div>
                            <div className='social'>
                                <ul>
                                    <li>
                                        <a href="https://www.instagram.com/vconcrete.in/" target='_blank'>
                                            <InstagramIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=61555548333677" target='_blank'>
                                            <FacebookIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/13641229/admin/feed/posts/" target='_blank'>
                                            <LinkedInIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href=" https://twitter.com/VConcrete_in" target='_blank'>
                                            <TwitterIcon />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="mailto:info@vgcpl.com" target='_blank'>
                                            <EmailIcon />
                                        </a>
                                    </li>
                                     */}
                                </ul>
                            </div>
                        </Col> 
                        <Col md={2}>
                            <h4>Quick Links</h4>
                            <ul>
                                <li><h6><Link to="/">Home</Link></h6></li>
                                <li><h6><Link to="/vconcrete">VConcrete</Link></h6></li>
                                <li><h6><Link to="/business">Business</Link></h6></li>
                                <li><h6><Link to="/plants">Plants</Link></h6></li>
                                <li><h6><Link to="/gallery">Gallery</Link></h6></li>
                                <li><h6><Link to="/contact-us">Contact-Us</Link></h6></li>
                                <li><h6><Link to="/privacy-policy">Privacy Policy</Link></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} className='contact'>
                            <h4>Registered Office</h4>
                            <RoomIcon /><p>54 & 55,Patel Nagar, <br /> At. Post: Baladia,<br />Taluka: Bhuj,<br /> District: Kutch, <br /> State :Gujarat,<br /> Pincode: 370427 <br />  </p>
                            <LocalPhoneIcon /><p><a href="tel:++919909008176">+91 9909008176</a></p>
                            <EmailIcon /><p><a href="mailto:info@vconcrete.in">info@vconcrete.in</a></p>
                        </Col>
                        <Col md={3} className='contact'>
                            <h4>Plants Locations</h4>
                            <Link to="/plants#desalpar"><LaunchIcon fontSize='medium'/> &nbsp;Desalpar Plant</Link><br /><br />
                            <Link to="/plants#gajod"><LaunchIcon fontSize='medium'/> &nbsp;Gajod Plant</Link><br/><br/>
                            <Link to="/plants#gandhidham"><LaunchIcon fontSize='medium'/> &nbsp;Gandhidham Plant</Link><br /><br />
                            <Link to="/plants#kandla"><LaunchIcon fontSize='medium'/> &nbsp;Kandla Plant</Link><br /><br />
                            <Link to="/plants#khavda"><LaunchIcon fontSize='medium'/> &nbsp;Khavda Plant</Link><br /><br />
                            <Link to="/plants#siracha"><LaunchIcon fontSize='medium'/> &nbsp;Siracha Plant</Link><br /><br />
                        </Col>
                    </Row>
                </Container>
                <div className="d-block d-sm-none menu-footer">
                    <Row className="p-3">
                        <Col className="b-right">
                            <Link to="/" className={urlHomepage} onClick={() => navigateTo('/')}>
                                Home
                            </Link>
                        </Col>
                        <Col className="b-right">
                            <Link to="/vconcrete" className={urlNcpl} onClick={() => navigateTo('/vconcrete')}>
                                VConcrete
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/contact-us" className={urlContact} onClick={() => navigateTo('/contact-us')}>
                                Contact Us
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="copyright">
                <p className='text-center'>&copy; {currentYear} VConcrete Pvt. Ltd. All rights reserved. Website designed and developed by Arkay Apps.</p>
            </div>
            <div className="bottom"></div>
        </div>
    )
}

export default Footer
