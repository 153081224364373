import React, { useState, useEffect } from 'react';
import { gallery } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Gallery1 from '../assets/Images/gallery/gallery1.png';
import Gallery2 from '../assets/Images/gallery/gallery2.png';
import Gallery3 from '../assets/Images/gallery/gallery3.png';
import Gallery4 from '../assets/Images/gallery/gallery4.png'
import Gallery5 from '../assets/Images/gallery/gallery5.png';
import Gallery6 from '../assets/Images/gallery/gallery6.png';
import Gallery7 from '../assets/Images/gallery/gallery7.png';
import Gallery8 from '../assets/Images/gallery/gallery8.png';
import Gallery9 from '../assets/Images/gallery/gallery9.png';
import Gallery10 from '../assets/Images/gallery/gallery10.png';
import Gallery11 from '../assets/Images/gallery/gallery11.png';
import Gallery12 from '../assets/Images/gallery/gallery12.png';
import Gallery13 from '../assets/Images/gallery/gallery13.png';
import Gallery14 from '../assets/Images/gallery/gallery14.png';
import Gallery15 from '../assets/Images/gallery/gallery15.png';
import Gallery16 from '../assets/Images/gallery/gallery16.png';
import Gallery17 from '../assets/Images/gallery/gallery17.png';
import Gallery18 from '../assets/Images/gallery/gallery18.png';
import Gallery19 from '../assets/Images/gallery/gallery19.png';
import Gallery20 from '../assets/Images/gallery/gallery20.png';
import Gallery21 from '../assets/Images/gallery/gallery21.png';
import Gallery22 from '../assets/Images/gallery/gallery22.png';
import Gallery23 from '../assets/Images/gallery/gallery23.png';
import Gallery24 from '../assets/Images/gallery/gallery24.png';
import Gallery25 from '../assets/Images/gallery/gallery25.png';
import Gallery26 from '../assets/Images/gallery/gallery26.png';
import Gallery27 from '../assets/Images/gallery/gallery27.png';
import Gallery28 from '../assets/Images/gallery/gallery28.png';
import Gallery29 from '../assets/Images/gallery/gallery29.png';
import Gallery30 from '../assets/Images/gallery/gallery30.png';
import Gallery31 from '../assets/Images/gallery/gallery31.png';
import Gallery32 from '../assets/Images/gallery/gallery32.png';
import Gallery33 from '../assets/Images/gallery/gallery33.png';
import Gallery34 from '../assets/Images/gallery/gallery34.png';


import ContactSection from '../components/ContactSection'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
};

// const allImages = [Gallery1,
// Gallery2,Gallery3,Gallery4,Gallery5,Gallery6,Gallery7,Gallery8,Gallery9,Gallery10,Gallery11,Gallery12,Gallery13,Gallery14,Gallery15,Gallery16,Gallery17,
//     Gallery18,Gallery19,Gallery20,Gallery21,Gallery22,Gallery23,Gallery24,Gallery25,Gallery26,Gallery27,Gallery28,Gallery29,Gallery30,Gallery31,Gallery32,Gallery33,Gallery34];

    const allImages = [
        {image: Gallery1, alt: 'Ready Mix Concrete(RMC) Plant Company in Kutch, Gujarat | VConcrete' },
        {image: Gallery2, alt: 'Concrete Service Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery3, alt: 'Concrete Manufacturer in Kutch, Gujarat | VConcrete'},        
        {image: Gallery4, alt: 'Best Construction Company in Kutch, Gujarat | VConcrete'},        
        {image: Gallery5, alt: 'Construction Contractors in Kutch, Gujarat | VConcrete'},        
        {image: Gallery6, alt: 'Quality Ready Mix Concrete Supplier in Kutch, Gujarat | VConcrete'},        
        {image: Gallery7, alt: 'Ready Mix Concrete(RMC) Plant Company in Kutch, Gujarat | VConcrete'},        
        {image: Gallery8, alt: 'Concrete Service Company in Kutch, Gujarat | VConcrete'},        
        {image: Gallery9, alt: 'Concrete Manufacturer in Kutch, Gujarat | VConcrete'},        
        {image: Gallery10,alt: 'Best Construction Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery11,alt: 'Construction Contractors in Kutch, Gujarat | VConcrete'},
        {image: Gallery12,alt: 'Quality Ready Mix Concrete Supplier in Kutch, Gujarat | VConcrete'},
        {image: Gallery13,alt: 'Ready Mix Concrete(RMC) Plant Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery14,alt: 'Concrete Service Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery15,alt: 'Concrete Manufacturer in Kutch, Gujarat | VConcrete'},
        {image: Gallery16,alt: 'Best Construction Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery17,alt: 'Construction Contractors in Kutch, Gujarat | VConcrete'},
        {image: Gallery18,alt: 'Quality Ready Mix Concrete Supplier in Kutch, Gujarat | VConcrete'},
        {image: Gallery19,alt: 'Ready Mix Concrete(RMC) Plant Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery20,alt: 'Concrete Service Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery21,alt: 'Concrete Manufacturer in Kutch, Gujarat | VConcrete'},
        {image: Gallery22,alt: 'Best Construction Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery23,alt: 'Construction Contractors in Kutch, Gujarat | VConcrete'},
        {image: Gallery24,alt: 'Quality Ready Mix Concrete Supplier in Kutch, Gujarat | VConcrete'},
        {image: Gallery25,alt: 'Ready Mix Concrete(RMC) Plant Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery26,alt: 'Concrete Service Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery27,alt: 'Concrete Manufacturer in Kutch, Gujarat | VConcrete'},
        {image: Gallery28,alt: 'Best Construction Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery29,alt: 'Construction Contractors in Kutch, Gujarat | VConcrete'},
        {image: Gallery30,alt: 'Quality Ready Mix Concrete Supplier in Kutch, Gujarat | VConcrete'},
        {image: Gallery31,alt: 'Ready Mix Concrete(RMC) Plant Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery32,alt: 'Concrete Service Company in Kutch, Gujarat | VConcrete'},
        {image: Gallery33,alt: 'Concrete Manufacturer in Kutch, Gujarat | VConcrete'},
        {image: Gallery34,alt: 'Best Construction Company in Kutch, Gujarat | VConcrete'},      
    ];

// const tabImagesCompleted = [GalleryImg2, GalleryImg10, GalleryImg11]
// const tabImagesAll = tabImagesOnGoing.concat(tabImagesCompleted);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Gallery = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [value, setValue] = useState(0);
    const [finalImage, setFinalImage] = useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const handleOpen = (image) => {
        setFinalImage(image)
        setOpen(true);
    }

    return (
        <div>
            <MetaTags>
                <title>{gallery.title}</title>
                <meta name="description" content={gallery.description} />
                <link rel="canonical" href={gallery.canonical} />
            </MetaTags>
            <section id="breadcrumbs" title='Quality Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Vconcrete' class="breadcrumbs">
                <div class="container">
                    <h1>Gallery</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>/ Gallery</li>
                    </ul>
                </div>
            </section>
           
            <section className="gallery">
                <Container>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                <Tab label="" {...a11yProps(0)} />
                            </Tabs> */}
                        </Box>
                        
                        <TabPanel value={value} index={0}>
                            <Row>
                           
                                {allImages.map((image, index) => {
                                    return (
                                        
                                        <Col key={index} md={4}>
                                            <div className='card-box-gallery'>
                                            <img onClick={() => handleOpen(image.image)} src={image.image} alt={image.alt} className="img-fluid" />
                                            </div>
                                        </Col>
                                        
                                    )
                                })}
                                
                            </Row>
                        </TabPanel>
                        

                        {/*<TabPanel value={value} index={2}>
                            <Row>
                                {tabImagesCompleted.map((image, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <img onClick={() => handleOpen(image)} src={image} alt="Gallery" className="img-fluid" />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </TabPanel> */}
                    </Box>
                </Container>
            </section>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={finalImage} alt="Gallery" className="img-fluid" />
                </Box>
            </Modal>
            <ContactSection />
        </div>
    )
}

export default Gallery
