import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import RoomIcon from "@mui/icons-material/Room";
import { plants } from "../MetaTags";
import MetaTags from "react-meta-tags";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MachineIcon from "@mui/icons-material/PrecisionManufacturing";

import { useLocation } from "react-router-dom";

export default function Plants(props) {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>{plants.title}</title>
        <meta name="description" content={plants.description} />
        <link rel="canonical" href={plants.canonical} />
      </MetaTags>
      <section
        id="breadcrumbs"
        title="Quality Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Vconcrete"
        class="breadcrumbs"
      >
        <div class="container">
          <h1>Plants</h1>
          <ul>
            <li>
              <a href="./">Home</a>
            </li>
            <li>/ Plants</li>
          </ul>
        </div>
      </section>
      <section id="desalpar">
        <Container>
          <Row>
            <Col md={6}>
              <h2>
                Desalpar <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>M1 KYB Conmat</p>
                <p>MP 30 Aquarius</p>
              </div>
              {/* <p>Some text about Galpadar plant/office</p> */}
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <a href="tel: +91 9909008176">
                  +91 9909008176 (Rajeshbhai Buchiya){" "}
                </a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <EmailIcon />
                </div>
                <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  Rev. Sur.No. 390/P1, <br />
                  Adani Power Plant Road,
                  <br />
                  <b>At: DESALPAR,</b>
                  <br /> Taluka: Mundra
                  <br /> Kutch Gujarat
                </p>
              </div>
            </Col>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14679.811770697346!2d69.6110052!3d23.0988184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dff8d6a2ee484ac!2sVGCPL!5e0!3m2!1sen!2sin!4v1668682681570!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="gajod" className="section-bg">
        <Container>
          <Row>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.323023294317!2d69.608482!3d23.0486161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x36597d87a1d46d8c!2sV%20concrete%20unit%201!5e0!3m2!1sen!2sin!4v1673587641439!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col md={6}>
              <h2>
                Gajod <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Stetter</p>
                <p>M1 Schwing Stetter</p>
              </div>
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <a href="tel: +91 9909008176">
                  {" "}
                  +91 9909008176 (Rajeshbhai Buchiya)
                </a>
                <br />
                <a href="tel: +91 9099017240"> +91 9099017240 (Mansukhbhai)</a>
                <br />
                <a
                  className="ms-lg-5 ms-md-2 ms-sm-5"
                  href="tel: +91 9909228888"
                >
                  {" "}
                  +91 9909228888 (Hiren Joshi)
                </a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <EmailIcon />
                </div>
                <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  Rev. Sr. No. 225/1, <br /> Bhuj Mundra Highway Road,
                  <br />
                  <b>At Post: Gajod</b>, <br />
                  Tal: Bhuj, Dist. : Kutch, Gujarat. <br />
                  Pin Code:370430{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="gandhidham">
        <Container>
          <Row>
            <Col md={6}>
              <h2>
                Gandhidham <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                <b>
                  <p>Machinaries</p>
                </b>
                <p>MP 70 Aquarius</p>
              </div>
              {/* <p>Some text about Galpadar plant/office</p> */}
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <a href="tel:+919228022888">+919228022888</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <EmailIcon />
                </div>
                <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  Plot No.9 &10, <br /> Rev.Sur.No. 57, <br />
                  <b> At & Post: MEGHPAR BORICHI</b>
                  <br /> Taluka: Anjar, Kutch
                  <br />
                  Gandhidham (Kandla) Gujarat
                  <br />{" "}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.897564243571!2d70.08028469999999!3d23.100845399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b8914dbbf0d7%3A0x334fd5dd958f2c4b!2sV%20Concrete!5e0!3m2!1sen!2sin!4v1673589239316!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="kandla" className="section-bg">
        <Container>
          <Row>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.897564243571!2d70.08028469999999!3d23.100845399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b8914dbbf0d7%3A0x334fd5dd958f2c4b!2sV%20Concrete!5e0!3m2!1sen!2sin!4v1673589239316!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>

            <Col md={6}>
              <h2>
                Kandla <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Stetter (Sanghi Cement)</p>
              </div>
              {/* <p>Some text about Galpadar plant/office</p> */}
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <a href="tel:+91 9909008176">+91 9909008176</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <EmailIcon />
                </div>
                <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  Near Sea Rock Hotel,
                  <br /> Behind IOCL Petrol Pump,
                  <br />
                  <b> At: KANDLA</b> <br /> Taluka: Gandhidham, Kutch
                  <br />
                  Gujarat
                  <br /> Pin Code: 370210
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="khavda">
        <Container>
          <Row>
            <Col md={6}>
              <h2>
                Khavda <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                {/* <h4>Machinaries</h4> */}
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Stetter</p>
                <p>M1 Schwing Stetter</p>
              </div>
              {/* <p>Some text about Galpadar plant/office</p> */}
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <a href="tel: +91 9909008176">
                  {" "}
                  +91 9909008176 (Rajeshbhai Buchiya)
                </a>
                <br />
                <a href="tel: +91 9099017240"> +91 9099017240 (Mansukhbhai)</a>
                <br />
                <a
                  className="ms-lg-5 ms-md-2 ms-sm-5"
                  href="tel: +91 9909228888"
                >
                  {" "}
                  +91 9909228888 (Hiren Joshi)
                </a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <EmailIcon />
                </div>
                <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete - RE Park</p>
                <p>
                  <b>Post & Taluka : Khavda </b>
                  <br />
                  Pincode : 370510
                  <br />
                  District Kutch,
                  <br />
                  Gujarat
                  <br />{" "}
                </p>
              </div>
            </Col>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3641.6658987947058!2d69.38508836507654!3d24.113242032926514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDA2JzQ4LjMiTiA2OcKwMjMnMTcuNiJF!5e0!3m2!1sen!2sin!4v1680524551898!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="siracha" className="section-bg">
        <Container>
          <Row>
            <Col md={6}>
              <iframe
                title="VGCPL Registered offic google map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14679.811770697346!2d69.6110052!3d23.0988184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dff8d6a2ee484ac!2sVGCPL!5e0!3m2!1sen!2sin!4v1668682681570!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: "5px solid #342475b9",
                  borderRadius: "2%",
                  maxWidth: "100%",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col md={6}>
              <h2>
                Siracha <span> Plant</span>
              </h2>
              <br />
              <div className="contacts">
                <div class="icon">
                  <MachineIcon />
                </div>
                <b>
                  <p>Machinaries</p>
                </b>
                <p>CP 30 Schwing Sletter (Sarrs Constructions)</p>
              </div>
              {/* <p>Some text about Galpadar plant/office</p> */}
              <div className="contacts">
                <div class="icon">
                  <PhoneIcon />
                </div>
                <a href="tel: +91 9909008176">
                  {" "}
                  +91 9909008176 (Rajeshbhai Buchiya)
                </a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <EmailIcon />
                </div>
                <a href="mailto:info@vgcpl.com">info@vgcpl.com</a>
              </div>
              <div className="contacts">
                <div class="icon">
                  <RoomIcon />
                </div>
                <p>V Concrete</p>
                <p>
                  SVCT Township, <br />
                  <b>At: SIRACHA,</b>
                  <br />
                  Taluka: Mundra
                  <br /> Kutch, Gujarat, <br />
                  Pin Code: 370405
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
