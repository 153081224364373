const defaultTitle = 'Best Ready Mix Concrete Supplier in Kutch, Gujarat | VConcrete'
const defaultDescription = 'VConcrete is the top manufacturer and supplier of ready-mix concrete (RMC) with its versatility and quality service. Check out the advantages of ready-mix concrete at VConcrete.'
const siteUrl = 'https://vconcrete.in/'

export const homepage = {
    title: defaultTitle,
    description: defaultDescription,
    canonical: siteUrl
}

export const business = {
    title: 'RMC Manufacturer and Supplier in Kutch, Gujarat | VConcrete ',
    description: 'VConcrete Ready Mix Concrete is a civil construction contractor and dealer with experience and expertise. We at VConcrete are always on standby to serve you the best.',
    canonical: siteUrl + 'business'
}
export const plants = {
    title: 'VConcrete RMC Plants | VConcrete',
    description: 'VConcrete is a ready mix concrete plant manufacturer. Desalpar Plant, Gajod Plant, Gandhidham Plant, Kandla Plant, Khavda Plant, and Siracha Plant are our RMC plants in Kutch.',
    canonical: siteUrl + 'plants'
}

export const concrete = {
    title: 'Quality Ready Mix Concrete Company in Kutch, Gujarat | VConcrete',
    description: 'VConcrete Ready Mix Concrete provides a wide range of modern-day machines and extraordinary human skills, making it one of the wisest choices for ready mix concrete. ',
    canonical: siteUrl + 'ready-mix-concrete'
}

export const vconcrete = {
    title: 'Civil Construction Company in Kutch, Gujarat | VConcrete',
    description: 'With the experienced eyes of mentors and qualified professionals, VConcrete Ready Mix Concrete Company believes in a no compromise policy for our premium quality of work.',
    canonical: siteUrl + 'vconcrete'
}

export const projects = {
    title: 'Projects | V Concrete',
    description: 'Projects | V Concrete',
    canonical: siteUrl + 'projects'
}

export const gallery = {
    title: 'VConcrete Gallery | VConcrete',
    description: 'Have a look at the gallery of VConcrete Ready Mix Concrete. VConcrete is the top manufacturer and supplier of Ready Mix Concrete (RMC) in the district of Kachchh, Gujarat.',
    canonical: siteUrl + 'gallery'
}


export const contactUs = {
    title: 'Contact VConcrete | Best Concrete Service Company in Kutch, Gujarat',
    description: 'Contact us for quality concrete construction and ready mix concrete plant manufacturing in Kutch, Gujarat. We are one of the top players in the business of civil construction.',
    canonical: siteUrl + 'contact-us'
}

export const privacyPolicy = {
    title: 'Privacy Policy | VConcrete',
    description: 'Privacy Policy by VConcrete Ready Mix Concrete will not reveal information about your email address or browsing activities to any third party. To know more, Visit us!',
    canonical: siteUrl + 'privacy-policy'
}

export const thankYou = {
    title: 'Thank You | V Concrete',
    description: 'Thank You | V Concrete',
    canonical: siteUrl + 'thank-you'
}
