// Core
import React, { useEffect } from 'react'
import { homepage } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import ContactSection from '../components/ContactSection'
// import MissionAndVision from '../components/MissionAndVision'
import { Container, Col, Row } from 'react-bootstrap'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import About from '../assets/Images/vconcrete_home_about.png'
import AboutNCPL from '../assets/Images/vgcpl-2.png'
import Vehicle1 from '../assets/Images/Vehicle-1-white-right.png'
import Machinery from '../components/Machinery'
// import Design1 from '../assets/Images/design-1.svg'
import GetInTouch from '../components/GetInTouch'
import MixedConcrete from '../components/MixedConcrete';
import Marquee from "react-fast-marquee";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ScrollAnimation from 'react-animate-on-scroll';

const Homepage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{homepage.title}</title>
                <meta name="description" content={homepage.description} />
                <link rel="canonical" href={homepage.canonical} />
            </MetaTags>
            <Slider />
            <section id='about' className="entry section-bg bg-1">
                <Container>
                    <Row>
                        <Col md={6} className='text-center'>
                            <img src={About} alt="Ready Mix Concrete(RMC) Supplier and Manufacturer in Kutch, Gujarat | VConcrete" className="img-fluid bg" />
                        </Col>
                        <Col md={6}>
                            <h1 className='homepage-heading'>VConcrete<span> : Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat</span></h1>
                            <div className="content">
                                <p><b>“TRUSTWORTHINESS”</b> of our valued Customers makes us stronger to grow more and more without any compromises in our premium Quality product and/or workmanship.</p>
                                <p>We are one of the Top-Players in the business of Civil Construction just because of our Integrity, Dedication, Courage, Honesty, Hard Work and Commitment towards our Clients.</p>
                                <p>Presently we are having <b>SIX</b> Ready Mix Plants for our own use as well as we cater to our Customers such as Adani Group, Reliance Group, AMW and many more local contractors in the district of Kutch, Gujarat, India.</p>
                            </div>
                            {/* <Link to="/vconcrete" className="link-primary-1">Read more <ArrowRightAltIcon /></Link> */}
                        </Col>
                    </Row>
                    <div className="part-2">
                        <Row>
                            <Col md={6}>
                                <h2>Our Other <span>company : VGCPL</span></h2>
                                <div className="content">
                                    <p><b>Late Sri Vishram Karsanbhai Gorasia,</b> the founder, later on became the Managing Director of the Company (VGCPvt.Ltd.), at the very onset of his career, he started to undertake very small construction ventures in his own home village (Baladia – Taluka: Bhuj – District: Kutch – Gujarat – India), but with a very <b><i>BIG</i></b>  foresight & <b><i>WIDE</i></b>  speculation of future development in Construction business.</p>
                                    <p>Slow & steadily, but with his dedication, hard work and passion he emerged to become one of the most trusted Civil Contractors in the local area of Kutch district.</p>
                                    <p>On attaining a certain age and to do some Social Service for the Mankind, he voluntarily retired himself from the job, handing over the baton, to his eldest son, Sri Jadavji Vishrambhai Gorasia. Now, to carry on the legacy of his father, Sri Jadavji Vishrambhai Gorasia, registered the family construction business into a registered private limited company in the year 2008, duly named as <b><i>“VISHRAMBHAI GORASIA CONSTRUCTION PRIVATE LIMITED”.</i></b></p>
                                    {/* <p><b>Vishrambhai Gorasia Construction Pvt. Ltd.</b> – a company established in 2008 is now one of the biggest names in construction business. Serving the land of Kachchh for about a decade, <b>VGCPL</b> has now established itself as a most reliable brand in construction business.</p>
                                    <p>We are keeping ourselves upgraded with equipments and technologies to complete projects on-time.</p> */}
                                </div>
                                <Link to="/business " className="link-primary-1">Read more <ArrowRightAltIcon /></Link>
                            </Col>
                            <Col md={6} className='text-center'>
                                <img src={AboutNCPL} alt="VGCPL Construction Pvt. Ltd. " className="img-fluid bg" />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="VConcrete RMC Truck" />
                </Marquee>
            </section>
            {/* <section id='projects'> 
                <Container>
                    <h2>Our <span>Projects</span></h2><br />
                    <p>Projects that we have completed</p>
                </Container>
                <Container fluid>
                    <SliderProjects />
                </Container>
            </section>
            */}
            <GetInTouch />
            <MixedConcrete/>
            {/* <MissionAndVision /> */}
            {/* <section className="counts section-bg">
                <Container fluid>
                    <Row>
                        <Col md={8} className='my-auto'>
                            <Container>
                                <Row>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-1">
                                            <span>10</span>
                                        </div>
                                        <h5>Years of Experience</h5>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-2">
                                            <span>05</span>
                                        </div>
                                        <h5>Lakh sq. ft. work</h5>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-1">
                                            <span>20</span>
                                        </div>
                                        <h5>Commercial Projects</h5>
                                    </Col>
                                    <Col md={3} xs={6}>
                                        <div className="card-count type-2">
                                            <span>20</span>
                                        </div>
                                        <h5>Satisfied Clients</h5>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col md={4} className='img-area'>
                            <img src={Design1} alt="" className="design-2" />
                            <div className="text-end">
                                <img src={Image2} alt="" className="img-fluid image-2" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section>
                <Container>
                    <div className="text-center">
                        <h2>Our <span>Clients</span></h2>
                        
                    </div>
                </Container>
                <Machinery />
            </section>

            {/* <section>
                <Container>
                    <h2>Our <span>Projects</span></h2><br />
                    <p>Our ongoing projects</p>
                </Container>
                <Container fluid>
                    <SliderProjectsOngoing />
                </Container>
            </section> */}
            
            <ContactSection />
        </div>
    )
}

export default Homepage
