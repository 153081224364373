// Core
import React from 'react'
// Library
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages
import Homepage from './pages/Homepage'
import Business from './pages/Business'
import VConcrete from './pages/VConcrete'
import ContactUs from './pages/ContactUs'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ThankYou from './pages/ThankYou'
import Plants from './pages/Plants'
import Gallery from './pages/Gallery'
import ConcreteDetails from './pages/ConcreteDetails'

function App() {
    return (
        <div>
            <Router>
                <Header />
                <Routes>
                    <Route exact path='/' element={<Homepage />} />
                    <Route exact path='/business' element={<Business />} />
                    <Route exact path='/plants' element={<Plants />} />
                    <Route exact path='/vconcrete' element={<VConcrete />} />
                    <Route exact path='/contact-us' element={<ContactUs />} />
                    <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route exact path='/thank-you' element={<ThankYou />} />
                    <Route exact path='/gallery' element={<Gallery />} />
                    <Route exact path='/ready-mix-concrete' element={<ConcreteDetails />} />
                    
                </Routes>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
