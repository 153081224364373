import React from 'react'
import { Carousel } from 'react-bootstrap'
import Slider1 from '../assets/Images/Slider-1.png'
import Slider2 from '../assets/Images/v-concrete-slider2.jpg'
import Slider3 from '../assets/Images/v-concrete-slider3.png'
import Slider4 from '../assets/Images/v-concrete-slider4.jpg'
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Slider = () => {
    return (    
        <div>
            <div className="slider">
                <Carousel controls={true} interval={3000} indicators={false}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider1} alt="Ready Mix Concrete(RMC) Supplier and Manufacturer in Kutch, Gujarat | VConcrete" />
                        <Carousel.Caption>
                            {/* <h1>V Concrete </h1>
                            <p>Ready Mixed Concrete</p> */}
                            {/* <a href="#about" className='d-none d-sm-block'><Button variant="primary">Know more <ArrowForwardIcon fontSize='small' /></Button></a> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider2} alt="Best Construction Company in Kutch, Gujarat | VConcrete" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider3} alt="Civil Construction Contractors in Kutch, Gujarat | VConcrete" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={Slider4} alt="Best Concrete Company in Kutch, Gujarat | VConcrete" />
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default Slider
