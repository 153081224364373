import React, { useEffect } from 'react'
import { thankYou } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

const ThankYou = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{thankYou.title}</title>
                <meta name="description" content={thankYou.description} />
                <link rel="canonical" href={thankYou.canonical} />
            </MetaTags>
            <section id="breadcrumbs" title='Quality Ready Mix Concrete(RMC) Supplier in Kutch, Gujarat | Vconcrete' class="breadcrumbs">
                <div class="container">
                    <h1>Thank You</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Thank You</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>

                    <h2 className="text-center tq">Thank You For Your Message..!!</h2>


                    <p className="text-center">Message Sent Successfully! We have recieved your mail and we will contact you shortly.</p>


                    <p className="text-center">Click to go on <Link to="/" className='link-primary'>Homepage</Link></p>

                </Container >
            </section >
        </div >
    )
}

export default ThankYou
